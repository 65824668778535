import React from "react";
import PropTypes from "prop-types";

import Layout from "../components/layout";

const IndexPage = ({ location }) => {
  return (
    <Layout location={location}>
      <h1>Monthly Payment</h1>
      <pre>
        <code>placeholder</code>
      </pre>
    </Layout>
  );
};

IndexPage.PropTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  title: PropTypes.string,
};

export default IndexPage;

export const data = {
  title: "Mortgagy Home",
};
