import PropTypes from "prop-types";
import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";

import styles from "./header.module.css";

const Header = ({ location, title }) => {
  const { allSitePage, site } = useStaticQuery(
    graphql`
      {
        site {
          siteMetadata {
            title
            menu {
              title
              uri
            }
          }
        }
        allSitePage(filter: { path: { nin: ["/dev-404-page/", "/404/"] } }) {
          edges {
            node {
              path
            }
          }
        }
      }
    `
  );
  const { title: siteTitle, menu } = site.siteMetadata;
  const localTitle = title || siteTitle;

  return (
    <header className={styles.header}>
      <div className={styles.title}>
        <Link to={location.pathname}>{localTitle}</Link>
      </div>
      <div className={styles.menu}>
        {menu.map(({ title, uri }) => (
          <div key={uri} className={styles.link}>
            <Link to={uri}>{title}</Link>
          </div>
        ))}
      </div>
    </header>
  );
};

Header.propTypes = {
  location: PropTypes.arrayOf({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  title: PropTypes.string,
};

export default Header;
