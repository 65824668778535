import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql, Link } from "gatsby";

import styles from "./footer.module.css";

const Layout = ({ location, title }) => (
  <StaticQuery
    query={graphql`
      {
        site {
          siteMetadata {
            author
            title
          }
        }
      }
    `}
    render={data => {
      const { author, title: siteTitle } = data.site.siteMetadata;
      const localTitle = title || siteTitle;

      return (
        <>
          <footer>
            <div>
              <Link to={location.pathname}>{localTitle}</Link>
            </div>
            <div>Privacy</div>
            <div>Warranty</div>
            <div>{`© ${author}`}</div>
          </footer>
        </>
      );
    }}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired,
  menu: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
};

export default Layout;
