import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";

import SEO from "../components/seo";
import Header from "./header";
import Footer from "./footer";

import "./layout.css";

const Layout = ({ children, location, title }) => {
  const { site } = useStaticQuery(
    graphql`
      {
        site {
          siteMetadata {
            author
            keywords
            title
          }
        }
      }
    `
  );
  const { keywords, title: siteTitle } = site.siteMetadata;
  const localTitle = title || siteTitle;

  return (
    <div>
      <SEO title={localTitle} keywords={keywords} location={location} />
      <Header location={location} title={localTitle} />
      <div>
        <main>{children}</main>
      </div>
      <Footer location={location} title={localTitle} />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.objectOf({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  title: PropTypes.string,
};

export default Layout;
